import React, { Component, createContext } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { Campaign, CampaignDocument } from './types/campaign'

interface IProps {}

interface IState {
  campaignsPromise: Promise<CampaignDocument[]>
}

export class CampaignProvider extends Component<IProps, IState> {
  // see docs/RetrievingDataFromFirestore.md for an explanation of this pattern
  constructor(props: IProps) {
    super(props)
    var initialized = false
    this.state = {
      campaignsPromise: new Promise(resolve =>
        firebase
          .firestore()
          .collection('campaigns')
          .onSnapshot(snapshot => {
            const campaignDocuments: CampaignDocument[] = snapshot.docs.map(
              doc => ({
                id: doc.id,
                campaign: doc.data() as Campaign,
              }),
            )
            if (initialized) {
              this.setState({
                campaignsPromise: Promise.resolve(campaignDocuments),
              })
            } else {
              initialized = true
              resolve(campaignDocuments)
            }
          }),
      ),
    }
  }

  updateCampaign = (id: string, update: Partial<Campaign>): Promise<void> =>
    firebase.firestore().collection('campaigns').doc(id).update(update)

  deleteCampaign = (id: string): Promise<void> =>
    firebase.firestore().collection('campaigns').doc(id).delete()

  createCampaign = (campaign: Campaign): Promise<void> =>
    firebase.firestore().collection('campaigns').add(campaign).then() // ignore the returned document

  getCampaign = (id: string): Promise<Campaign | null> =>
    this.state.campaignsPromise
      .then(docs => docs.filter(doc => doc.id === id))
      .then(docs => (docs.length > 0 ? docs[0].campaign : null))

  getCampaignsByArtist = (artistId: string): Promise<CampaignDocument[]> =>
    this.state.campaignsPromise.then(docs =>
      docs.filter(doc => doc.campaign.artistId === artistId),
    )

  getAllCampaigns = (): Promise<CampaignDocument[]> =>
    this.state.campaignsPromise

  render() {
    const {
      createCampaign,
      updateCampaign,
      getCampaign,
      getCampaignsByArtist,
      getAllCampaigns,
      deleteCampaign,
    } = this
    return (
      <CampaignContext.Provider
        value={{
          createCampaign,
          updateCampaign,
          getCampaign,
          getCampaignsByArtist,
          getAllCampaigns,
          deleteCampaign,
        }}
      >
        {this.props.children}
      </CampaignContext.Provider>
    )
  }
}

type CampaignContextProps = {
  createCampaign(campaign: Campaign): Promise<void>
  updateCampaign(id: string, update: Partial<Campaign>): Promise<void>
  deleteCampaign(id: string): Promise<void>
  getCampaign(id: string): Promise<Campaign | null>
  getCampaignsByArtist(artistId: string): Promise<CampaignDocument[]>
  getAllCampaigns(): Promise<CampaignDocument[]>
}

export const CampaignContext: React.Context<CampaignContextProps> =
  createContext({} as CampaignContextProps)
