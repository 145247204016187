import React, { Component } from 'react'
import { CircularProgress, Grid, Box } from '@material-ui/core'

interface IProps {
  data: any | LoadingType
}

/**
 * This component is intended to wrap other components that should only be
 * displayed when they have data. While the `data` is `LOADING`, this
 * displays a CircularProgress indicator. Otherwise, it displays the
 * wrapped components
 */
export class LoadingData extends Component<IProps> {
  render() {
    return this.props.data === LOADING ? (
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      </Box>
    ) : (
      <>{this.props.children}</>
    )
  }
}

// This type can optionally be used as a placeholder for data that is being retrieved
// It is more semantically meaningful than undefined or null
export const LOADING = 'DATA_IS_LOADING'
export type LoadingType = typeof LOADING
