import React from 'react'
import { Component } from 'react'
import { AppBar, Button, Toolbar, Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import logo from '../assets/logo.png'
import styled from 'styled-components'
import heroImage from '../assets/hero-guitar.jpg'
import Footer from '../component_library/Footer'
import { Link } from 'react-router-dom'
import URLMap from '../URLMap'

const StyledAppBar = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
})(AppBar)

const Hero = styled.div`
  background-color: #000000;
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${heroImage});
  background-position: center;
  background-size: cover;
`

const Logo = styled.img`
  width: 60vw;
`

const Tagline = styled.div`
  color: #ffffff;
  margin-top: 32px;
`

class Home extends Component {
  render() {
    return (
      <div>
        <StyledAppBar position="fixed" elevation={0}>
          <Toolbar>
            <Box mr="auto"></Box>
            <Button color="inherit" to={URLMap.Login} component={Link}>
              Login
            </Button>
          </Toolbar>
        </StyledAppBar>
        <Hero>
          <Box>
            <Logo src={logo} alt="Original Dog Logo" />
            <Tagline>
              <Typography variant="h1">Release Yourself</Typography>
              <Typography variant="h5">
                India's first funding and marketing platform for Original Music
              </Typography>
            </Tagline>
          </Box>
        </Hero>
        <Footer />
      </div>
    )
  }
}

export default Home
