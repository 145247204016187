import DateFnsUtils from '@date-io/date-fns'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { Field, Formik } from 'formik'
import React, { useContext, useState, useEffect } from 'react'
import CoreAppBar from '../component_library/CoreAppBar'
import Footer from '../component_library/Footer'
import { ArtistContext } from '../shared/ArtistContext'
import { AuthContext, AuthState } from '../shared/AuthContext'
import { CampaignContext } from '../shared/CampaignContext'
import { PaymentContext } from '../shared/PaymentContext'
import {
  LOADING,
  LoadingData,
  LoadingType,
} from '../shared/component_library/LoadingData'
import { SnackbarContext } from '../shared/SnackbarContext'
import { Campaign, CAMPAIGN_STATUS } from '../shared/types/campaign'
import CampaignNotFound from './CampaignNotFound'
import Unauthorized from './Unauthorized'
import DeleteRecord, {
  RecordCategoryType,
} from '../component_library/DeleteRecord'
import { useHistory } from 'react-router-dom'
import URLMap from '../URLMap'

interface CampaignProps {
  match: {
    params: {
      id: string
    }
  }
}

const CampaignView: React.FC<CampaignProps> = (props: CampaignProps) => {
  const id = props.match.params.id
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const auth = useContext(AuthContext)
  const { getCampaign, updateCampaign, deleteCampaign } =
    useContext(CampaignContext)
  const { getPayments } = useContext(PaymentContext)
  const { wrapWithSnackbar } = useContext(SnackbarContext)
  const { getArtist } = useContext(ArtistContext)
  const [campaign, setCampaign] = useState<Campaign | null | LoadingType>(
    LOADING,
  )
  const [artistName, setArtistName] = useState<string | LoadingType>(LOADING)
  const [paymentIDs, setPaymentIDs] = useState<string[] | LoadingType>(LOADING)
  const history = useHistory()

  useEffect(() => {
    getCampaign(id).then(campaign => {
      setCampaign(campaign)
      if (campaign != null) {
        getArtist(campaign.artistId).then(artist =>
          // if the artist doesn't exist we should still display the record so the admin can see the problem
          setArtistName(artist !== null ? artist.name : '<UNKNOWN ARTIST>'),
        )
      }
    })
  }, [id, getCampaign, getArtist])

  useEffect(() => {
    getPayments()
      .then(docs => docs.filter(doc => doc.payment.campaignId === id))
      .then(docs => docs.map(doc => doc.id))
      .then(setPaymentIDs)
  }, [id, getPayments])

  if (!campaign) {
    // TODO: return 404 page
    return <CampaignNotFound />
  }

  if (auth.authState === AuthState.UNAUTHENTICATED) {
    return <Unauthorized />
  }

  const NUM_STATUSES = Object.keys(CAMPAIGN_STATUS).length / 2 // there are two keys per enum value (one in each direction)

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2}>
        <Grid container justify="center">
          {auth.authState === AuthState.RETRIEVING && <CircularProgress />}
          {auth.authState === AuthState.AUTHENTICATED && (
            <LoadingData data={campaign}>
              {campaign !== LOADING && paymentIDs !== LOADING && (
                <Grid item xs md={8}>
                  <Typography variant="h3">Campaign</Typography>
                  <Formik
                    initialValues={campaign}
                    enableReinitialize
                    onSubmit={values => {
                      setIsFormSubmitting(true)
                      wrapWithSnackbar(
                        updateCampaign(id, values).finally(() =>
                          setIsFormSubmitting(false),
                        ),
                      )
                    }}
                  >
                    {props => (
                      <form onSubmit={props.handleSubmit}>
                        <Box mt={2}>
                          <TextField
                            value={campaign.artistId}
                            label="Artist ID"
                            variant="outlined"
                            fullWidth
                          ></TextField>
                          <LoadingData data={artistName}>
                            {artistName !== LOADING && (
                              <TextField
                                value={artistName}
                                label="Artist Name"
                                variant="outlined"
                                fullWidth
                              ></TextField>
                            )}
                          </LoadingData>

                          <Field
                            as={TextField}
                            name="title"
                            label="Title"
                            variant="outlined"
                            fullWidth
                          />
                          <Field
                            as={Select}
                            name="status"
                            label="Status"
                            variant="outlined"
                            fullWidth
                          >
                            {Array.from({ length: NUM_STATUSES }).map(
                              (_, i) => (
                                <MenuItem key={i} value={i}>
                                  {CAMPAIGN_STATUS[i]}
                                </MenuItem>
                              ),
                            )}
                          </Field>
                          <Field
                            as={TextField}
                            name="description"
                            label="Description"
                            variant="outlined"
                            multiline
                            rows={6}
                            rowsMax={10}
                            fullWidth
                          />
                          <Field
                            as={TextField}
                            type="Number"
                            name="goal"
                            label="Goal"
                            variant="outlined"
                            fullWidth
                          />
                          <Field
                            as={TextField}
                            type="Number"
                            name="totalContributions"
                            label="Total Contributions Raised"
                            variant="outlined"
                            fullWidth
                          />
                          <Field
                            as={TextField}
                            name="city"
                            label="City"
                            variant="outlined"
                            fullWidth
                          />
                          <Field
                            as={TextField}
                            name="contactNumber"
                            label="Contact Number"
                            variant="outlined"
                            fullWidth
                          />
                          <Field
                            as={TextField}
                            name="videoUrl"
                            label="Video URL"
                            variant="outlined"
                            onChange={props.handleChange}
                            fullWidth
                          />
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Field
                              as={KeyboardDatePicker}
                              name="start"
                              label="Start Date"
                              format="dd/MM/yyyy"
                              fullWidth
                              onChange={(d: Date) =>
                                props.setFieldValue('start', d.getTime())
                              }
                            ></Field>
                            <Field
                              as={KeyboardDatePicker}
                              name="end"
                              label="End Date"
                              format="dd/MM/yyyy"
                              fullWidth
                              onChange={(d: Date) =>
                                props.setFieldValue('end', d.getTime())
                              }
                            ></Field>
                          </MuiPickersUtilsProvider>
                        </Box>
                        <Box mt={2}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={isFormSubmitting}
                          >
                            Save
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Formik>

                  <DeleteRecord
                    category={RecordCategoryType.Campaign}
                    id={id}
                    references={paymentIDs}
                    deleteFn={() => {
                      setIsFormSubmitting(true)
                      wrapWithSnackbar(
                        deleteCampaign(id)
                          .then(() => history.push(URLMap.Campaigns))
                          .finally(() => setIsFormSubmitting(false)),
                      )
                    }}
                  />
                </Grid>
              )}
            </LoadingData>
          )}
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default CampaignView
