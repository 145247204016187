import React, { Component, createContext } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

interface IProps {}

export enum AuthState {
  RETRIEVING,
  UNAUTHENTICATED,
  AUTHENTICATED,
}

export type UserAuth = {
  uid: string // the logged in user
  email: string
  verified: boolean
}

interface IState {
  authState: AuthState
  user: UserAuth | null
}

export class AuthProvider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { authState: AuthState.RETRIEVING, user: null }
  }

  componentDidMount() {
    // Instead of using onAuthStateChanged, this updates whenever the user's ID token changes
    // This means that it will be called when a user refreshes a page,
    // which gives us the opportunity to distinguish between a known logged out (null) user and
    // the intermediate state when retrieving the logged in user
    firebase.auth().onIdTokenChanged(user => this.updateState(user))
  }

  /**
   * Register a new user and create a record in the "users" database
   */
  register = (name: string, email: string, password: string): Promise<void> =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(credential =>
        firebase
          .firestore()
          .collection('users')
          .doc(credential.user?.uid)
          .set({
            name,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => credential.user),
      )
      .then(user => {
        this.updateState(user)
        return user?.sendEmailVerification()
      })

  signIn = (email: string, password: string): Promise<string | null> =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(credential => credential.user)
      .then(user => {
        this.updateState(user)
        return user && user.uid ? user.uid : null
      })

  signOut = (): Promise<void> =>
    firebase
      .auth()
      .signOut()
      .then(() => this.updateState(null))

  /**
   * Sign in. If the user is not an admin (with a predefined uid), log out and throw an error
   */
  signInAsAdmin = (email: string, password: string): Promise<void> =>
    this.signIn(email, password)
      .then(
        uid =>
          uid === 'We6PttQV9SRoDXxwNQ15zJwVjWl1' ||
          uid === 'LiUxhZE1cGSu1xo1GtHfZRFO7zQ2' ||
          uid === 'KJauIIaSq6QW3K2M9gKtznSeVPO2',
      )
      .then(isAdmin => {
        if (!isAdmin) {
          return this.signOut().then(() => {
            throw new Error('Not an admin')
          })
        }
      })

  resetPassword = (email: string): Promise<void> =>
    firebase.auth().sendPasswordResetEmail(email)

  sendVerificationLink = (): Promise<void> => {
    const user = firebase.auth().currentUser
    return user == null
      ? Promise.reject('No authenticated user')
      : user.sendEmailVerification()
  }

  render() {
    const { authState, user } = this.state
    const {
      register,
      signIn,
      signOut,
      signInAsAdmin,
      resetPassword,
      sendVerificationLink,
    } = this
    return (
      <AuthContext.Provider
        value={{
          authState,
          user,
          register,
          signIn,
          signOut,
          signInAsAdmin,
          resetPassword,
          sendVerificationLink,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }

  private updateState(user: firebase.User | null) {
    this.setState({
      authState: user?.uid
        ? AuthState.AUTHENTICATED
        : AuthState.UNAUTHENTICATED,
      user: user?.uid
        ? {
            uid: user.uid,
            email: user.email ?? '',
            verified: user.emailVerified,
          }
        : null,
    })
  }
}

type AuthContextProps = {
  authState: AuthState
  user: UserAuth | null
  register(name: string, email: string, password: string): Promise<void>
  signIn(email: string, password: string): Promise<string | null>
  signOut(): Promise<void>
  signInAsAdmin(email: string, password: string): Promise<void>
  resetPassword(email: string): Promise<void>
  sendVerificationLink(): Promise<void>
}

export const AuthContext: React.Context<AuthContextProps> = createContext(
  {} as AuthContextProps,
)
