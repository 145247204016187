type recordId = string

export interface PaymentRequest {
  campaignId: recordId
  firstname: string
  phone: number
  email: string
  amount: number // in rupees
}

export enum PaymentStatus {
  PENDING,
  FAILED,
  COMPLETE,
}

export type Payment = PaymentRequest & {
  timestamp: number
  status: PaymentStatus
}

export interface PayURequestAugmentation {
  key: string
  txnid: string
  productinfo: string
  hash: string
}

export const defaultPaymentRequest = (
  payment?: Partial<PaymentRequest>,
): PaymentRequest => {
  return Object.assign(
    {
      campaignId: '',
      firstname: '',
      phone: 0,
      email: String,
      amount: suggestedPayments[0], // the minimum payment amount
    },
    payment,
  )
}

export interface PaymentDocument {
  id: recordId
  payment: Payment
}

export const suggestedPayments = [500, 1000, 1500, 2000, 2500, 3000];
