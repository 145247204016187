import React from 'react'
import { Typography, Grid, Box } from '@material-ui/core'
import CoreAppBar from '../component_library/CoreAppBar'
import Footer from '../component_library/Footer'

interface IProps {}

const CampaignNotFound: React.FC<IProps> = () => {
  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Typography variant="h3">Campaign Not Found</Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default CampaignNotFound
