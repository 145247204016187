import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import firebase from 'firebase/compat/app'
import { getApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import firebaseConfig from './firebase_config'
import Campaigns from './pages/Campaigns'
import CampaignView from './pages/CampaignView'
import Home from './pages/Home'
import Login from './pages/Login'
import { PaymentsView } from './pages/PaymentsView'
import { PaymentView } from './pages/PaymentView'
import { PaymentProcess } from './pages/PaymentProcess'
import Portal from './pages/Portal'
import ViewArtist from './pages/ViewArtist'
import { ArtistProvider } from './shared/ArtistContext'
import { AuthProvider } from './shared/AuthContext'
import { CampaignProvider } from './shared/CampaignContext'
import { SnackbarProvider } from './shared/SnackbarContext'
import theme from './Theme'
import URLMap from './URLMap'
import { PaymentProvider } from './shared/PaymentContext'

firebase.initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(getAuth(), 'http://localhost:9099')
  connectFirestoreEmulator(getFirestore(), 'localhost', 8080)
  connectFunctionsEmulator(getFunctions(getApp()), 'localhost', 5001)
}

const GlobalStyle = createGlobalStyle`
  body {
    position: relative;
    min-height: 100vh;
    padding-bottom: 3rem;
  }
`

function App(): JSX.Element {
  return (
    <Router>
      <CssBaseline />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ArtistProvider>
            <CampaignProvider>
              <PaymentProvider>
                <SnackbarProvider>
                  <Route path="/" exact component={Home} />
                  <Route path={URLMap.Login} exact component={Login} />
                  <Route path={URLMap.Portal} exact component={Portal} />
                  <Route
                    path={`${URLMap.Artist}/:id`}
                    exact
                    component={ViewArtist}
                  />
                  <Route path={URLMap.Campaigns} exact component={Campaigns} />
                  <Route
                    path={`${URLMap.Campaign}/:id`}
                    exact
                    component={CampaignView}
                  />
                  <Route
                    path={URLMap.Payments}
                    exact
                    component={PaymentsView}
                  />
                  <Route
                    path={`${URLMap.Payment}/:id`}
                    exact
                    component={PaymentView}
                  />
                  <Route
                    path={URLMap.PaymentProcess}
                    exact
                    component={PaymentProcess}
                  />
                </SnackbarProvider>
              </PaymentProvider>
            </CampaignProvider>
          </ArtistProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
