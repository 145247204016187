import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { format } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import CoreAppBar from '../component_library/CoreAppBar'
import {
  Payment,
  PaymentStatus,
  PaymentDocument,
} from '../shared/types/payment'
import { PaymentContext } from '../shared/PaymentContext'
import { AuthContext, AuthState } from '../shared/AuthContext'
import Unauthorized from './Unauthorized'
import {
  LOADING,
  LoadingType,
  LoadingData,
} from '../shared/component_library/LoadingData'

export const PaymentsView: React.FC = () => {
  const auth = useContext(AuthContext)
  const { getPayments } = useContext(PaymentContext)
  const [payments, setPayments] = useState<PaymentDocument[] | LoadingType>(
    LOADING,
  )
  const [sortedPayments, setSortedPayments] = useState<
    PaymentDocument[] | LoadingType
  >(LOADING)

  useEffect(() => {
    getPayments()
      .then(payments =>
        payments.sort((a, b) => b.payment.timestamp - a.payment.timestamp),
      )
      .then(setPayments)
  }, [getPayments])

  //export functionality
  // flatten the structure so the document id is part of the payment structure
  const [paymentsExport, setPaymentsExport] = useState<
    (Payment & { id: string })[]
  >([])
  useEffect(() => {
    if (sortedPayments !== LOADING) {
      setPaymentsExport(
        sortedPayments.map(doc =>
          Object.assign({ id: doc.id }, doc.payment, {
            status: PaymentStatus[doc.payment.status],
          }),
        ),
      )
    }
  }, [sortedPayments])

  // sort functionality
  type Columns = 'ID' | 'CampaignID' | 'Name' | 'Amount' | 'Status' | 'Timestamp'
  type SortSpec = {
    column: Columns
    order: 'asc' | 'desc'
  }
  const [sortSpec, setSortSpec] = useState<SortSpec>({
    column: 'Timestamp',
    order: 'desc',
  })

  useEffect(() => {
    if (payments !== LOADING) {
      setSortedPayments(
        [...payments].sort((a, b) => {
          let diff = 0
          switch (sortSpec.column) {
            case 'ID':
              diff = a.id > b.id ? 1 : -1
              break
            case 'CampaignID':
                diff = a.payment.campaignId > b.payment.campaignId ? 1 : -1
                break
            case 'Name':
              diff = a.payment.firstname > b.payment.firstname ? 1 : -1
              break
            case 'Amount':
              diff = a.payment.amount - b.payment.amount
              break
            case 'Status':
              diff = a.payment.status > b.payment.status ? 1 : -1
              break
            case 'Timestamp':
              diff = a.payment.timestamp - b.payment.timestamp
          }
          return sortSpec.order === 'asc' ? diff : -1 * diff
        }),
      )
    }
  }, [payments, sortSpec])

  const SortedTableHeading = (column: Columns) => (
    <TableCell>
      <TableSortLabel
        active={sortSpec.column === column}
        direction={sortSpec.column === column ? sortSpec.order : 'asc'}
        onClick={() =>
          setSortSpec(
            sortSpec.column === column
              ? {
                  column: sortSpec.column,
                  order: sortSpec.order === 'asc' ? 'desc' : 'asc',
                }
              : { column, order: 'asc' },
          )
        }
      >
        <Typography variant="h6">{column}</Typography>
      </TableSortLabel>
    </TableCell>
  )

  if (auth.authState === AuthState.UNAUTHENTICATED) {
    return <Unauthorized />
  }

  const backgroundColor = (status: PaymentStatus) => {
    switch(status) {
      case PaymentStatus.COMPLETE:
        return '#ddffdd'
      case PaymentStatus.FAILED:
        return '#ffdddd'
      case PaymentStatus.PENDING:
        return '#ffddbb'
      default:
        return 'white'
    }
  }

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2}>
        <Grid container justify="center">
          {auth.authState === AuthState.RETRIEVING && <CircularProgress />}
          {auth.authState === AuthState.AUTHENTICATED && (
            <Grid item xs md={8}>
              <Typography variant="h3">Payments</Typography>
              <LoadingData data={sortedPayments}>
                {sortedPayments !== LOADING && (
                  <>
                    <CSVLink
                      filename="payments-export.csv"
                      data={paymentsExport}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button variant="contained" color="secondary">
                        Export
                      </Button>
                    </CSVLink>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {SortedTableHeading('ID')}
                            {SortedTableHeading('CampaignID')}
                            {SortedTableHeading('Name')}
                            {SortedTableHeading('Amount')}
                            {SortedTableHeading('Status')}
                            {SortedTableHeading('Timestamp')}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {sortedPayments.map(doc => (
                            <TableRow
                              key={doc.id}
                              component={Link}
                              to={`/payment/${doc.id}`}
                              style={{ textDecoration: 'none', backgroundColor: backgroundColor(doc.payment.status)}}
                            >
                              <TableCell>{doc.id}</TableCell>
                              <TableCell>{doc.payment.campaignId}</TableCell>
                              <TableCell>{doc.payment.firstname}</TableCell>
                              <TableCell>{doc.payment.amount}</TableCell>
                              <TableCell>
                                {PaymentStatus[doc.payment.status]}
                              </TableCell>
                              <TableCell>
                                {format(
                                  new Date(doc.payment.timestamp),
                                  'dd/MM/yyyy',
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </LoadingData>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}
