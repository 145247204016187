import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles"

const theme: ThemeOptions = createMuiTheme({
  typography: {
    fontFamily: "Nunito, Roboto, Arial, sans-serif",
    h1: {
      fontSize: "3rem",
    },
    h3: {
      fontSize: "2rem",
      marginTop: "2rem",
      marginBottom: "1rem",
    },
    h6: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    body1: {
      marginBottom: "1em",
    },
  },
  overrides: {
    MuiButton: {
      text: {
        fontWeight: "bold",
      },
      contained: {
        fontWeight: "bold",
      },
    },
  },
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#f50057",
    },
  },
})

export default theme
