import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Select,
  TextField,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { format } from 'date-fns'
import { Field, Formik } from 'formik'
import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import CoreAppBar from '../component_library/CoreAppBar'
import { PaymentContext } from '../shared/PaymentContext'
import { AuthContext, AuthState } from '../shared/AuthContext'
import { Payment, PaymentStatus } from '../shared/types/payment'
import {
  LoadingType,
  LoadingData,
  LOADING,
} from '../shared/component_library/LoadingData'
import { SnackbarContext } from '../shared/SnackbarContext'
import Unauthorized from './Unauthorized'
import DeleteRecord, {
  RecordCategoryType,
} from '../component_library/DeleteRecord'
import URLMap from '../URLMap'

interface PaymentProps {
  match: {
    params: {
      id: string
    }
  }
}

export const PaymentView: React.FC<PaymentProps> = (props: PaymentProps) => {
  const id = props.match.params.id
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const auth = useContext(AuthContext)
  const { getPayment, updatePayment, deletePayment } =
    useContext(PaymentContext)
  const { wrapWithSnackbar } = useContext(SnackbarContext)
  // null implies the payment doesn't exist
  const [payment, setPayment] = useState<Payment | null | LoadingType>(LOADING)
  const history = useHistory()

  useEffect(() => {
    getPayment(id)
      .then(setPayment)
      .catch(() => setPayment(null))
  }, [id, getPayment])

  if (auth.authState === AuthState.UNAUTHENTICATED) {
    return <Unauthorized />
  }

  const NUM_STATUSES = Object.keys(PaymentStatus).length / 2 // there are two keys per enum value (one in each direction)

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2}>
        <Grid container justify="center">
          {auth.authState === AuthState.RETRIEVING && <CircularProgress />}
          {auth.authState === AuthState.AUTHENTICATED && (
            <Grid item xs md={8}>
              <Typography variant="h3">Payment</Typography>
              <LoadingData data={payment}>
                {payment !== LOADING &&
                  (payment === null ? (
                    <Typography>Payment not found</Typography>
                  ) : (
                    <>
                      <Formik
                        initialValues={payment}
                        enableReinitialize
                        onSubmit={values => {
                          setIsFormSubmitting(true)
                          wrapWithSnackbar(
                            updatePayment(id, values).finally(() =>
                              setIsFormSubmitting(false),
                            ),
                          )
                        }}
                      >
                        {props => (
                          <form onSubmit={props.handleSubmit}>
                            <Box mt={2}>
                              <TextField
                                value={payment.campaignId}
                                label="Campaign ID"
                                variant="outlined"
                                fullWidth
                              ></TextField>
                              <Field
                                as={TextField}
                                name="firstname"
                                label="Name"
                                variant="outlined"
                                fullWidth
                              />
                              <Field
                                as={TextField}
                                name="email"
                                label="Email"
                                variant="outlined"
                                fullWidth
                              />
                              <Field
                                as={TextField}
                                name="amount"
                                label="Amount"
                                variant="outlined"
                                type="number"
                                fullWidth
                              />
                              <Field
                                as={TextField}
                                name="phone"
                                label="Phone Number"
                                variant="outlined"
                                type="number"
                                fullWidth
                              />
                              <Field
                                as={Select}
                                name="status"
                                label="Status"
                                variant="outlined"
                                fullWidth
                              >
                                {Array.from({ length: NUM_STATUSES }).map(
                                  (_, i) => (
                                    <MenuItem key={i} value={i}>
                                      {PaymentStatus[i]}
                                    </MenuItem>
                                  ),
                                )}
                              </Field>
                              <Field
                                as={TextField}
                                name="timestamp"
                                label="Timestamp"
                                variant="outlined"
                                type="number"
                                fullWidth
                              />
                              {/* display the timestamp in date format */}
                              <TextField
                                value={format(
                                  new Date(props.values.timestamp),
                                  'dd/MM/yyyy',
                                )}
                                label="Corresponding Date"
                                variant="outlined"
                                fullWidth
                              />
                              <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                disabled={isFormSubmitting}
                              >
                                Save
                              </Button>
                            </Box>
                          </form>
                        )}
                      </Formik>

                      <DeleteRecord
                        category={RecordCategoryType.Payment}
                        id={id}
                        references={[]}
                        deleteFn={() => {
                          setIsFormSubmitting(true)
                          wrapWithSnackbar(
                            deletePayment(id)
                              .then(() => history.push(URLMap.Payments))
                              .finally(() => setIsFormSubmitting(false)),
                          )
                        }}
                      />
                    </>
                  ))}
              </LoadingData>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}
