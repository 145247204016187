import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Typography,
  Grid,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  CircularProgress,
} from '@material-ui/core'
import { format } from 'date-fns'
import CoreAppBar from '../component_library/CoreAppBar'
import { AuthContext, AuthState } from '../shared/AuthContext'
import { CampaignContext } from '../shared/CampaignContext'
import { CampaignDocument } from '../shared/types/campaign'
import Footer from '../component_library/Footer'
import Unauthorized from './Unauthorized'
import { CAMPAIGN_STATUS } from '../shared/types/campaign'
import {
  LoadingType,
  LoadingData,
  LOADING,
} from '../shared/component_library/LoadingData'

const Campaigns = () => {
  const auth = useContext(AuthContext)
  const { getAllCampaigns } = useContext(CampaignContext)
  const [campaignDocuments, setCampaignDocuments] = useState<
    CampaignDocument[] | LoadingType
  >(LOADING)

  useEffect(() => {
    getAllCampaigns()
      .then(docs =>
        // sort in reverse chronological order
        docs.sort((a, b) => b.campaign.start - a.campaign.start),
      )
      .then(setCampaignDocuments)
  }, [getAllCampaigns])

  if (auth.authState === AuthState.UNAUTHENTICATED) {
    return <Unauthorized />
  }

  const backgroundColor = (status: CAMPAIGN_STATUS) => {
    switch(status) {
      case CAMPAIGN_STATUS.ACTIVE:
        return '#ddffdd'
      case CAMPAIGN_STATUS.DECLINED:
        return '#ffdddd'
      case CAMPAIGN_STATUS.REQUESTED:
        return '#ffddbb'
      default:
        return 'white'
    }
  }

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2}>
        <Grid container justify="center">
          {auth.authState === AuthState.RETRIEVING && <CircularProgress />}
          {auth.authState === AuthState.AUTHENTICATED && (
            <Grid item xs md={8}>
              <Typography variant="h3">Campaigns</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6">ID</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Artist ID</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Title</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Start</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">End</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Status</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <LoadingData data={campaignDocuments}>
                      {campaignDocuments !== LOADING &&
                        campaignDocuments.map(doc => (
                          <TableRow
                            key={doc.id}
                            component={Link}
                            to={`/campaign/${doc.id}`}
                            style={{ textDecoration: 'none', backgroundColor: backgroundColor(doc.campaign.status)}}

                          >
                            <TableCell>{doc.id}</TableCell>
                            <TableCell>{doc.campaign.artistId}</TableCell>
                            <TableCell>{doc.campaign.title}</TableCell>
                            <TableCell>
                              {format(
                                new Date(doc.campaign.start),
                                'dd/MM/yyyy',
                              )}
                            </TableCell>
                            <TableCell>
                              {format(new Date(doc.campaign.end), 'dd/MM/yyyy')}
                            </TableCell>
                            <TableCell>
                              {CAMPAIGN_STATUS[doc.campaign.status]}
                            </TableCell>
                          </TableRow>
                        ))}
                    </LoadingData>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default Campaigns
