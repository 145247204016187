import { PerformanceType } from './PerformanceType'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export interface Artist {
  achievements: string
  ambitions: string
  avatarUrl: string
  backgroundUrl: string
  challenges: string
  city: string
  email: string
  enabled: boolean
  facebookUrl: string
  genre: string[]
  performanceType: PerformanceType
  influences: string
  instagramUrl: string
  name: string
  originStory: string
  performance: string
  profileVideoUrl: string
  portfolioUrls: string[]
  state: string
  style: string
  tagline: string
  twitterUrl: string
  youTubeUrl: string
  appleMusicUrl: string
  spotifyUrl: string
  popular: boolean // admin-controlled parameter
  new_releases: boolean // admin-controlled parameter
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
}

export const defaultArtist = (artist?: Partial<Artist>): Artist => {
  return Object.assign(
    {
      achievements: '',
      ambitions: '',
      avatarUrl: '',
      backgroundUrl: '',
      challenges: '',
      city: '',
      email: '',
      enabled: true,
      facebookUrl: '',
      genre: [],
      performanceType: PerformanceType.OTHER,
      influences: '',
      instagramUrl: '',
      name: '',
      originStory: '',
      performance: '',
      profileVideoUrl: '',
      portfolioUrls: [],
      state: '',
      style: '',
      tagline: '',
      twitterUrl: '',
      youTubeUrl: '',
      appleMusicUrl: '',
      spotifyUrl: '',
      popular: false,
      new_releases: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
    artist,
  )
}

export interface ArtistDocument {
  id: string
  artist: Artist
}
