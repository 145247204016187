import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Launch from '@material-ui/icons/Launch'
import { Field, FieldArray, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CoreAppBar from '../component_library/CoreAppBar'
import { ArtistContext } from '../shared/ArtistContext'
import {
  LOADING,
  LoadingData,
  LoadingType,
} from '../shared/component_library/LoadingData'
import { MAX_PROFILE_GENRES } from '../shared/Constants'
import { SnackbarContext } from '../shared/SnackbarContext'
import { Artist } from '../shared/types/artist'
import { PerformanceType } from '../shared/types/PerformanceType'
import ArtistNotFound from './ArtistNotFound'
import DeleteRecord, {
  RecordCategoryType,
} from '../component_library/DeleteRecord'
import { CampaignContext } from '../shared/CampaignContext'
import { useHistory } from 'react-router-dom'
import URLMap from '../URLMap'

interface IProps {
  match: {
    params: {
      id: string
    }
  }
}

const ViewArtist: React.FC<IProps> = (props: IProps) => {
  const id = props.match.params.id
  const { getArtist, updateArtist, getGenres, deleteArtist } =
    useContext(ArtistContext)
  const { getCampaignsByArtist } = useContext(CampaignContext)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const { wrapWithSnackbar } = useContext(SnackbarContext)
  const [artist, setArtist] = useState<Artist | null | LoadingType>(LOADING)
  const [genres, setGenres] = useState<string[]>([])
  const [campaignIds, setCampaignIds] = useState<string[] | LoadingType>(
    LOADING,
  )
  const history = useHistory()

  useEffect(() => {
    getArtist(id).then(setArtist)
  }, [id, getArtist])

  useEffect(() => {
    getGenres().then(knownGenres => setGenres(knownGenres))
  }, [getGenres])

  useEffect(() => {
    getCampaignsByArtist(id)
      .then(docs => docs.map(doc => doc.id))
      .then(setCampaignIds)
  }, [id, getCampaignsByArtist])

  if (!artist) {
    // TODO: return 404 page
    return <ArtistNotFound />
  }

  const onNewGenre = (formik: any) => {
    const newGenre = formik.values.newGenre
    setGenres(genres.concat(newGenre))
    formik.setFieldValue('genre', formik.values.genre.concat(newGenre))
    formik.setFieldValue('newGenre', '')
  }

  return (
    <>
      <CoreAppBar />
      <Grid container justify="center">
        <Grid item xs md={8} lg={6}>
          <LoadingData data={artist}>
            {artist !== LOADING && campaignIds !== LOADING && (
              <>
                <Box mt={2}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/portal">Artists</Link>
                    <Typography color="textPrimary" style={{ marginBottom: 0 }}>
                      {artist.name}
                    </Typography>
                  </Breadcrumbs>
                </Box>
                <Typography variant="h3">{artist.name}</Typography>
                <Typography variant="body1">{artist.email}</Typography>
                <Formik
                  initialValues={Object.assign(artist, { newGenre: '' })}
                  enableReinitialize
                  onSubmit={values => {
                    // remove the newGenre before updating the artist
                    const { newGenre, ...artist } = values
                    setIsFormSubmitting(true)
                    wrapWithSnackbar(
                      updateArtist(id, artist).finally(() =>
                        setIsFormSubmitting(false),
                      ),
                    )
                  }}
                >
                  {props => (
                    <form onSubmit={props.handleSubmit}>
                      <Box
                        border={1}
                        marginBottom={2}
                        padding={2}
                        borderRadius={5}
                        borderColor={'lightgrey'}
                      >
                        <InputLabel>Admin-controlled categories</InputLabel>
                        <FormControlLabel
                          name="popular"
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          value={props.values.popular}
                          checked={props.values.popular}
                          control={<Checkbox color="primary" />}
                          label="Mark as popular"
                        />
                        <FormControlLabel
                          name="new_releases"
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          value={props.values.new_releases}
                          checked={props.values.new_releases}
                          control={<Checkbox color="primary" />}
                          label="Artist has New Releases"
                        />
                      </Box>
                      <Box mt={2}>
                        <Field
                          as={TextField}
                          name="avatarUrl"
                          label="Avatar Url"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  href={artist.avatarUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Launch />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Field
                          as={TextField}
                          name="backgroundUrl"
                          label="Background Url"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  href={artist.backgroundUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Launch />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <FormControl fullWidth>
                          <InputLabel>Genre</InputLabel>
                          <Select
                            name="genre"
                            multiple
                            value={props.values.genre}
                            onChange={props.handleChange}
                            renderValue={selected => {
                              return (
                                <Box>
                                  {(selected as string[]).map(genre => (
                                    <Chip label={genre} key={genre} />
                                  ))}
                                </Box>
                              )
                            }}
                          >
                            {genres.map(genre => {
                              const isSelected = props.values.genre.some(
                                g => g === genre,
                              )
                              return (
                                <MenuItem
                                  key={genre}
                                  value={genre}
                                  disabled={
                                    !isSelected &&
                                    props.values.genre.length >=
                                      MAX_PROFILE_GENRES
                                  }
                                >
                                  <Checkbox checked={isSelected} />
                                  <ListItemText primary={genre} />
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        <TextField
                          label="New Genre (optional)"
                          variant="outlined"
                          name="newGenre"
                          value={props.values.newGenre}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                        />
                        <Button
                          color="primary"
                          onClick={() => onNewGenre(props)}
                          disabled={
                            props.values.genre.length >= MAX_PROFILE_GENRES ||
                            props.values.newGenre.length === 0 ||
                            genres.includes(props.values.newGenre)
                          }
                        >
                          ADD
                        </Button>
                        <FormControl fullWidth>
                          <InputLabel>Type</InputLabel>
                          <Select
                            name="performanceType"
                            value={props.values.performanceType}
                            onChange={props.handleChange}
                          >
                            {Object.values(PerformanceType).map(type => {
                              return (
                                <MenuItem key={type} value={type}>
                                  {type}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        <Box
                          border={1}
                          marginBottom={2}
                          padding={2}
                          borderRadius={5}
                          borderColor={'lightgrey'}
                        >
                          <InputLabel>Portfolio URLs</InputLabel>
                          <FieldArray name="portfolioUrls">
                            {({ remove, push }) => (
                              <>
                                {props.values.portfolioUrls.map((url, idx) => (
                                  <div key={idx}>
                                    {/* TODO: The DeleteIcon should be on the same line as the url */}
                                    <IconButton onClick={() => remove(idx)}>
                                      <DeleteIcon />
                                    </IconButton>
                                    <Field
                                      as={TextField}
                                      name={`portfolioUrls.${idx}`}
                                      label={`video url ${idx + 1}`}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                ))}
                                <IconButton onClick={() => push('')}>
                                  <AddIcon />
                                </IconButton>
                              </>
                            )}
                          </FieldArray>
                        </Box>
                        <Field
                          as={TextField}
                          name="city"
                          label="City"
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="state"
                          label="State"
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="tagline"
                          label="Tagline"
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="profileVideoUrl"
                          label="Profile Video Url"
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="originStory"
                          label="Origin Story"
                          multiline
                          rowsMax={4}
                          rows={4}
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="ambitions"
                          label="Ambitions"
                          multiline
                          rowsMax={4}
                          rows={4}
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="challenges"
                          label="Write about the struggles you or your band has had"
                          multiline
                          rowsMax={4}
                          rows={4}
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="achievements"
                          label="Professional accomplishments"
                          multiline
                          rowsMax={4}
                          rows={4}
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="influences"
                          label="What influences your style"
                          multiline
                          rowsMax={4}
                          rows={4}
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="style"
                          label="Describe your style of music"
                          multiline
                          rowsMax={4}
                          rows={4}
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="performance"
                          label="What's it like being at one of your gigs"
                          multiline
                          rowsMax={4}
                          rows={4}
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="facebookUrl"
                          label="Facebook Url"
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="twitterUrl"
                          label="Twitter Url"
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="instagramUrl"
                          label="Instagram Url"
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="youTubeUrl"
                          label="YouTube Url"
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="spotifyUrl"
                          label="Spotify Url"
                          variant="outlined"
                          fullWidth
                        />
                        <Field
                          as={TextField}
                          name="appleMusicUrl"
                          label="appleMusic Url"
                          variant="outlined"
                          fullWidth
                        />
                        <FormControlLabel
                          name="enabled"
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          value={props.values.enabled}
                          checked={props.values.enabled}
                          control={<Checkbox color="primary" />}
                          label="Profile published"
                        />
                      </Box>
                      <Box mt={2}>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={isFormSubmitting}
                        >
                          Save
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>

                <DeleteRecord
                  category={RecordCategoryType.Artist}
                  id={id}
                  references={campaignIds}
                  deleteFn={() => {
                    setIsFormSubmitting(true)
                    wrapWithSnackbar(
                      deleteArtist(id)
                        .then(() => history.push(URLMap.Portal))
                        .finally(() => setIsFormSubmitting(false)),
                    )
                  }}
                />
              </>
            )}
          </LoadingData>
        </Grid>
      </Grid>
    </>
  )
}

export default ViewArtist
