import React, { Component, createContext } from 'react'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

interface IProps {}

interface IState {
  isOpen: boolean
  isSuccess: boolean
}

export class SnackbarProvider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isOpen: false,
      isSuccess: true,
    }
  }

  /**
   * Execute the promise and return the same result or error
   * Either way, open the snackbar to indicate success or fail
   */
  wrapWithSnackbar = (promise: Promise<any>): Promise<any> =>
    promise
      .then(res => {
        this.setState(
          () => ({ isOpen: true, isSuccess: true }),
          () =>
            setTimeout(() => this.setState(() => ({ isOpen: false })), 4000),
        )
        return res
      })
      .catch(err => {
        this.setState(
          () => ({ isOpen: true, isSuccess: false }),
          () =>
            setTimeout(() => this.setState(() => ({ isOpen: false })), 4000),
        )
        return Promise.reject(err)
      })

  render() {
    const { wrapWithSnackbar } = this
    const { isOpen, isSuccess } = this.state
    return (
      <SnackbarContext.Provider value={{ wrapWithSnackbar }}>
        {this.props.children}
        <Snackbar open={isOpen}>
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={isSuccess ? 'success' : 'error'}
          >
            {isSuccess ? 'Success' : 'Unable to complete action'}
          </MuiAlert>
        </Snackbar>
      </SnackbarContext.Provider>
    )
  }
}

type SnackbarContextProps = {
  wrapWithSnackbar: (promise: Promise<any>) => Promise<any>
}

export const SnackbarContext: React.Context<SnackbarContextProps> = createContext(
  {} as SnackbarContextProps,
)
