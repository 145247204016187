import React, { useState } from 'react'
import {
  Box,
  Button,
  List,
  ListItem,
  Typography,
  TextField,
} from '@material-ui/core'

import { Link } from 'react-router-dom'
import URLMap from '../URLMap'

export enum RecordCategoryType {
  Artist = 'Artist',
  Campaign = 'Campaign',
  Payment = 'Payment',
}

interface IProps {
  category: RecordCategoryType
  id: string // the record ID to delete
  references: string[] // the list of records that reference this one (and should be deleted first)
  deleteFn: () => void
}
const DeleteRecord: React.FC<IProps> = (props: IProps) => {
  const [typedID, setTypedID] = useState<string>('')
  const onTypedIDChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTypedID(e.target.value)

  const referencedUrl =
    props.category === RecordCategoryType.Artist
      ? URLMap.Campaign
      : props.category === RecordCategoryType.Campaign
      ? URLMap.Payment
      : URLMap.Portal

  return (
    <Box mt={2} paddingTop={4} marginY={4}>
      <Typography variant="h5">Delete {props.category}</Typography>
      {props.references.length > 0 ? (
        <>
          <Typography variant="h6">
            This {props.category} cannot be deleted. It is referenced by the
            following records:
          </Typography>
          <Box style={{ maxHeight: 200, overflow: 'scroll' }}>
            <List dense>
              {props.references.map(reference => (
                <ListItem key={reference}>
                  <Button
                    color="inherit"
                    to={`${referencedUrl}/${reference}`}
                    component={Link}
                  >
                    {reference}
                  </Button>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h6">
            Warning: This cannot be undone. Please type the {props.category} ID
            (see URL) to confirm
          </Typography>
          <TextField
            fullWidth
            value={typedID}
            onChange={onTypedIDChange}
          ></TextField>
          <Box paddingTop={2}>
            <Button
              variant="contained"
              color="secondary"
              disabled={typedID !== props.id}
              onClick={props.deleteFn}
            >
              DELETE
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default DeleteRecord


