import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const StyledFooter = styled.div`
  background-color: #111111;
  color: #ffffff;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Copyright = styled(Typography)`
  padding: 8px 16px;
  margin: 0;
`;

const Footer = () => {
  return (
    <StyledFooter id="footer">
      <Copyright variant="body1">
        &copy; 2020 Original Dog. All Rights Reserved.
      </Copyright>
    </StyledFooter>
  );
};

export default Footer;
