import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Typography,
  Grid,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  CircularProgress,
} from '@material-ui/core'
import CoreAppBar from '../component_library/CoreAppBar'
import { AuthContext, AuthState } from '../shared/AuthContext'
import { ArtistContext } from '../shared/ArtistContext'
import Footer from '../component_library/Footer'
import Unauthorized from './Unauthorized'
import URLMap from '../URLMap'
import { ArtistDocument } from '../shared/types/artist'
import {
  LoadingType,
  LOADING,
  LoadingData,
} from '../shared/component_library/LoadingData'

const Portal = () => {
  const auth = useContext(AuthContext)
  const { getArtists } = useContext(ArtistContext)
  const [artistDocuments, setArtistDocuments] = useState<
    ArtistDocument[] | LoadingType
  >(LOADING)

  useEffect(() => {
    getArtists()
      .then(docs =>
        // sort in reverse chronological order
        docs.sort((a, b) => b.artist.createdAt.seconds - a.artist.createdAt.seconds),
      )
      .then(setArtistDocuments)
  }, [getArtists])

  if (auth.authState === AuthState.UNAUTHENTICATED) {
    return <Unauthorized />
  }

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2}>
        <Grid container justify="center">
          {auth.authState === AuthState.RETRIEVING && <CircularProgress />}
          {auth.authState === AuthState.AUTHENTICATED && (
            <Grid item xs md={8}>
              <Typography variant="h3">Artists</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6">ID</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6">Email</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <LoadingData data={artistDocuments}>
                    {artistDocuments !== LOADING && (
                      <TableBody>
                        {artistDocuments.map(doc => (
                          <TableRow
                            key={doc.id}
                            component={Link}
                            to={`${URLMap.Artist}/${doc.id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <TableCell>{doc.id}</TableCell>
                            <TableCell>{doc.artist.name}</TableCell>
                            <TableCell>{doc.artist.email}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </LoadingData>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default Portal
