import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export enum CAMPAIGN_STATUS {
  REQUESTED,
  ACTIVE,
  DECLINED,
}

export enum DETAILED_CAMPAIGN_STATUS {
  REQUESTED,
  DECLINED,
  FUNDED,
  PENDING,
  UNDERFUNDED,
  ACTIVE,
  INVALID,
}

export interface Campaign {
  artistId: string
  title: string
  description: string
  goal: number // rupees
  start: Timestamp
  end: Timestamp
  videoUrl: string
  city: string
  contactNumber: string
  status: CAMPAIGN_STATUS
  createdAt: firebase.firestore.Timestamp
  totalContributions: number // updated by the server on every successful payment
}

type Timestamp = number

export const defaultCampaign = (campaign?: Partial<Campaign>): Campaign => {
  return Object.assign(
    {
      artistId: '',
      title: '',
      description: '',
      goal: 0,
      start: Date.now(),
      end: Date.now(),
      videoUrl: '',
      city: '',
      contactNumber: '',
      status: CAMPAIGN_STATUS.REQUESTED,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      totalContributions: 0
    },
    campaign,
  )
}

export const getDetailedStatus = (campaign: Campaign): DETAILED_CAMPAIGN_STATUS => {
  const now = Date.now()
  switch (campaign.status) {
    case CAMPAIGN_STATUS.REQUESTED:
      return DETAILED_CAMPAIGN_STATUS.REQUESTED
    case CAMPAIGN_STATUS.DECLINED:
      return DETAILED_CAMPAIGN_STATUS.DECLINED
    case CAMPAIGN_STATUS.ACTIVE:
      if (campaign.totalContributions >= campaign.goal) return DETAILED_CAMPAIGN_STATUS.FUNDED
      if (now < campaign.start) return DETAILED_CAMPAIGN_STATUS.PENDING
      if (now > campaign.end) return DETAILED_CAMPAIGN_STATUS.UNDERFUNDED
      return DETAILED_CAMPAIGN_STATUS.ACTIVE
    default:
      return DETAILED_CAMPAIGN_STATUS.INVALID
  }
}

export interface CampaignDocument {
  id: string
  campaign: Campaign
}
