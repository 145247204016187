import React, { useContext, useState } from 'react'
import {
  AppBar,
  Button,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Tabs,
  Tab,
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { AuthContext, AuthState } from '../shared/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import URLMap from '../URLMap'

enum AppBarTabs {
  Artists = 0,
  Campaigns = 1,
  Payments = 2,
  PaymentProcess = 3,
}

interface IProps {}

// This no longer uses the AuthContext correctly.
// I did not fix it because we should reuse the CoreAppBar from the client

const CoreAppBar: React.FC<IProps> = () => {
  const auth = useContext(AuthContext)
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const defaultTabIndex =
    history.location.pathname.indexOf(URLMap.Campaigns) >= 0
      ? AppBarTabs.Campaigns
      : history.location.pathname.indexOf(URLMap.Payments) >= 0
      ? AppBarTabs.Payments
      : history.location.pathname.indexOf(URLMap.PaymentProcess) >= 0
      ? AppBarTabs.PaymentProcess
      : AppBarTabs.Artists

  const [activeTab, setActiveTab] = useState<AppBarTabs>(defaultTabIndex)

  const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleCloseProfileMenu = () => setAnchorEl(null)
  const handleLogoutClick = () => {
    auth.signOut()
    history.push(URLMap.Root)
  }

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar>
        <Box mr="auto">
          <Tabs value={activeTab}>
            <Tab
              label="Artists"
              onClick={() => {
                history.push(URLMap.Portal)
                setActiveTab(AppBarTabs.Artists)
              }}
            />
            <Tab
              label="Campaigns"
              onClick={() => {
                history.push(URLMap.Campaigns)
                setActiveTab(AppBarTabs.Campaigns)
              }}
            />
            <Tab
              label="Payments"
              onClick={() => {
                history.push(URLMap.Payments)
                setActiveTab(AppBarTabs.Payments)
              }}
            />
            <Tab
              label="Payment Process"
              onClick={() => {
                history.push('/payment-process')
                setActiveTab(AppBarTabs.PaymentProcess)
              }}
            />
          </Tabs>
        </Box>
        {/* Ignore the case where auth.user === "RETRIEVING" */}

        {auth.authState === AuthState.AUTHENTICATED && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenProfileMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleCloseProfileMenu}
            >
              <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
            </Menu>
          </div>
        )}
        {auth.authState === AuthState.UNAUTHENTICATED && (
          <Button color="inherit" to={URLMap.Login} component={Link}>
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default CoreAppBar
