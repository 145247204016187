export const MAX_PROFILE_GENRES = 3

// the time that an artist appears in the "New OriginalDogs category"
// 30 days
export const NEW_ARTIST_WINDOW_SECONDS = 30 * 24 * 60 * 60 

// the number of artists that must be in a genre category for it to be displayed
export const MIN_CATEGORY_SIZE = 3

export const adminEmail: string = 'team@originaldog.in'
export const releaseYourselfUrl: string =
  'https://www.youtube.com/watch?v=DBF5wz300PE'

export const paymentGateway: string =
  process.env.NODE_ENV === 'development'
    ? 'https://sandboxsecure.payu.in/_payment'
    : 'https://secure.payu.in/_payment'

export const paymentCallback: string =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5001/original-dog-871bc/us-central1/paymentCallback'
    : 'https://us-central1-original-dog-871bc.cloudfunctions.net/paymentCallback'
