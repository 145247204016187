import React, { useContext } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CoreAppBar from '../component_library/CoreAppBar'
import { AuthContext, AuthState } from '../shared/AuthContext'
import Unauthorized from './Unauthorized'

export const PaymentProcess: React.FC = () => {
  const auth = useContext(AuthContext)

  if (auth.authState === AuthState.UNAUTHENTICATED) {
    return <Unauthorized />
  }

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs md={8}>
            <Typography variant="h3">Payment Process</Typography>
            <Typography variant="body1">
              The payment provider (PayU Money) uses old and temperamental
              technology. We have added our own layer of security to harden the
              system. However, there are still hacks and weaknesses that should
              be noted. It is confusing and non-idiomatic so having solid
              documentation may help debug future issues or guide future
              improvements.
            </Typography>
            <Typography variant="h5">Data Flows</Typography>
            <Typography variant="body1">
              <ol>
                <li>
                  Payments start at the <code>FanFund</code> component. The user
                  inputs the relevant payment information.
                </li>
                <li>
                  We intercept the form submission and send the parameters to a
                  Firebase cloud function named <code>contribute</code>, which
                  <ul>
                    <li>adds a timestamp</li>
                    <li>
                      adds a status of <code>PENDING</code>
                    </li>
                    <li>
                      saves the record in the <code>payments</code> collection
                      of our Firestore database
                    </li>
                    <li>
                      augments the payment information with
                      <ul>
                        <li>
                          the PayU Money <code>key</code> parameter. This is
                          used to identify OriginalDog to PayU Money and we have
                          to send it back to the client, so it should not be
                          considered a cryptographic key. There is a separate{' '}
                          <code>salt</code> parameter, which is (confusingly)
                          used as a secret key. Even though we send the{' '}
                          <code>key</code> back to the client, we save it on the
                          server so clients or attackers will need to perform
                          dynamic analysis in order to retrieve it.
                        </li>
                        <li>
                          the transaction ID for the record in our database
                        </li>
                        <li>
                          a hardcoded string identifying this as an OD campaign
                          contribution
                        </li>
                        <li>
                          a cryptographic hash of all the parameters including
                          the <code>key</code> and <code>salt</code> (which are
                          both issued by PayU Money). As long as the{' '}
                          <code>salt</code> remains secret, this hash proves
                          that we constructed the payment.
                        </li>
                      </ul>
                    </li>
                    <li>returns the augmented payment information.</li>
                  </ul>
                </li>
                <li>
                  The <code>FanFund</code> component fills out the augmented
                  information in hidden form fields and submits the form. This
                  executes a <code>POST</code> request to a PayU Money endpoint.
                  One of the hidden fields is a callback URL that points to our
                  Firebase cloud function named <code>paymentCallback</code>.
                </li>
                <li>
                  The user is redirected to the PayU Money payment workflow.
                  They provide their name, credit card number, two-factor
                  authentication, etc. We have no control over this process.
                </li>
                <li>
                  Once the payment is complete (or cancelled), the user is
                  redirected to our <code>paymentCallback</code> cloud function.
                </li>
                <ul>
                  <li>
                    The redirect includes the payment information and a new
                    cryptographic hash of all the parameters (in a different
                    order from our original hash). We validate this hash. As
                    long as the <code>salt</code> remains secret, this hash
                    proves the PayU Money constructed the response.
                  </li>
                  <li>
                    We also retrieve our <code>PENDING</code> payment record and
                    ensure their response matches our record.
                  </li>
                  <li>
                    PayU Money's response will include a parameter indicating if
                    the payment was successful. We update our own record's
                    status to <code>COMPLETE</code> or <code>FAILED</code>{' '}
                    accordingly.
                  </li>
                  <li>
                    We redirect the user to a page that informs them if the
                    payment was complete, failed, or there was an unexpected
                    response.
                  </li>
                </ul>
              </ol>
            </Typography>
            <Typography variant="h5">
              Recovering from unexpected responses
            </Typography>
            <Typography variant="body1">
              After saving a record of the <code>PENDING</code> payment, we lose
              visibility of the actual payment until PayU Money redirects the
              user back to our cloud function. If something fails in the mean
              time (eg. the user closes their browser, or their computer
              crashes, or there was a network failure), we don't know if the
              payment was successful. Similarly, if PayU Money returns a
              response that doesn't match our records, we can't tell if the
              error occured before or after the payment.
            </Typography>
            <Typography variant="body1">
              Such a scenario will need to be resolved manually. An adminstrator
              should use the PayU Money portal to identify the transaction, and
              use the Original Dog Admin portal to set the status of the{' '}
              <code>PENDING</code> payment to <code>COMPLETE</code> or{' '}
              <code>FAILED</code>
            </Typography>
            <Typography variant="body1">
              For the sake of illustration, it's worth noting an example that
              occured during testing. I set the contributor's phone number to
              1234, which PayU Money's testing workflow refused to accept. They
              allowed me to change the phone number, but the successful payment
              details didn't match the <code>PENDING</code> record, so I was
              redirected to the "Unexpected Response" page.
            </Typography>
            <Typography variant="h5">Total contributions</Typography>
            <Typography variant="body1">
              Whenever a payment is updated in our database, the{' '}
              <code>onWrite</code> cloud function is triggered. This sums the
              total value of <code>COMPLETE</code> payments and updates the{' '}
              <code>totalContributions</code> record in the corresponding
              campaign, which is used when displaying funding status. This is
              not independently synchronised with the payment provider. As an
              adminstrator, you can change the payment records arbitrarily,
              which will trigger this function and affect how campaigns are
              displayed. Naturally, modification to this database have no
              bearing on the actual payments that were received.
            </Typography>
            <Typography variant="h5">Input Validation</Typography>
            <Typography variant="body1">
              Instead of providing a flexible API that accepts cross-origin
              requests, The PayU money workflow requires us to <code>POST</code>{' '}
              a form with the expected fields. This has many implications:
              <ul>
                <li>
                  Some of the information should not be shown to the user, and
                  is included in hidden form fields.
                </li>
                <li>
                  To account for server-provided values, we intercept the call
                  and add the server response to additional hidden fields, as
                  explained above.
                </li>
                <li>
                  Our user interface is directly tied to the PayU Money's API.
                  For example, they require a <code>firstname</code> field and
                  an optional <code>lastname</code> field. We cannot choose our
                  own interface so instead, we use a <code>firstname</code>{' '}
                  field that we've labelled "Name". Similarly, they require a
                  numeric <code>phone</code> field so our interface forces a
                  numeric input rather than allowing and parsing other natural
                  phone number formats.
                </li>
                <li>
                  The PayU Money documentation implies overly restrictive input
                  validation. For instance, the name fields cannot have
                  non-alphabet characters. Actual names have spaces, hyphens and
                  apostrophes. Luckily, their testing API doesn't enforce these
                  restrictions and it is unlikely their production version would
                  behave differently. Consequently, we're ignoring them too (in
                  violation of the documentation). If valid payments fail
                  unexpectedly, this should be one of the first things to
                  investigate.
                </li>
              </ul>
            </Typography>
            <Typography variant="h5">Payment Access Control</Typography>
            <Typography variant="body1">
              When the payment is complete we would like to direct the user to a
              receipt page so they can see the details. Since contributors
              aren't necessarily authenticated, we cannot use their user ID to
              restrict access. Instead, we have a created a 10-minute window
              where the database rules allow the payment to be viewed by anyone.
              In order to view someone else's payment an attacker would need to
              determine their payment transaction ID within 10 minutes of the
              payment being completed. Any attacker who could plausibly
              determine this (eg. by watching the packets on the wire or by
              running a virus on the user's machine) would already have access
              to the payment information. Thererfore, this seems like a
              reasonable trade off.
            </Typography>
            <Typography variant="body1">
              After 10 minutes the page expires and users will need to contact
              an administrator to get the receipt.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
