enum URLMap {
  Root = '/',
  Login = '/auth/login',
  Portal = '/portal',
  Artist = '/artist',
  Campaigns = '/campaigns',
  Campaign = '/campaign',
  Payments = '/payments',
  Payment = '/payment',
  PaymentProcess = '/payment-process',
}

export default URLMap
