import React, { useContext, useState } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Button, Typography, Grid, Box, TextField } from "@material-ui/core"
import CoreAppBar from "../component_library/CoreAppBar"
import { useFormik } from "formik"
import { AuthContext, AuthState } from "../shared/AuthContext"
import Footer from "../component_library/Footer"
import URLMap from "../URLMap"

interface IProps {}

// This no longer uses the AuthContext correctly.
// I did not fix it because we should reuse the Login component from the client

const Login: React.FC<RouteComponentProps<IProps>> = (
  props: RouteComponentProps
) => {
  const VALID_EMAIL_REGEX: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const auth = useContext(AuthContext)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState("")
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: ({ email, password }) => {
      setFormErrorMessage("")
      setIsFormSubmitting(true)
      auth
        .signInAsAdmin(email, password)
        .then(() => {
          props.history.push(URLMap.Portal)
        })
        .catch((error) => {
          setFormErrorMessage(error.message)
          setIsFormSubmitting(false)
        })
    },
    validate: ({ email, password }) => {
      return {
        ...(!isValidEmail(email) && {
          email: "Please provide a properly formatted email address",
        }),
        ...((!password || password.length < 8) && {
          password: "Please provide a password at least 8 characters long",
        }),
      }
    },
  })

  const isValidEmail = (email: string): boolean =>
    VALID_EMAIL_REGEX.test(String(email).toLowerCase())

  // If you load the home page but you're already logged in, go straight to the portal
  if (auth.authState === AuthState.AUTHENTICATED) {
    props.history.push(URLMap.Portal)
  }

  return (
    <>
      <CoreAppBar />
      <Box p={2} mt={2} textAlign="center">
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <Typography variant="h3">Login</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={2} mt={2}>
        <Grid container justify="center">
          <Grid item xs sm={6}>
            <form onSubmit={formik.handleSubmit}>
              <Box mt={2}>
                <TextField
                  error={formik.touched.email && formik.errors.email != null}
                  name="email"
                  label="Email"
                  type="email"
                  variant="outlined"
                  helperText={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null
                  }
                  fullWidth
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  error={
                    formik.touched.password && formik.errors.password != null
                  }
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  helperText={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : null
                  }
                  fullWidth
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box textAlign="center" mt={2}>
                <Typography component="p" color="secondary">
                  {formErrorMessage.length ? formErrorMessage : "\u00A0"}
                </Typography>
              </Box>
              <Box textAlign="center" mt={2}>
                <Button
                  disabled={isFormSubmitting}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default withRouter(Login)
